import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import formloader from "../../images/formloading.svg";
import { Link, useNavigate } from "react-router-dom";
import img2 from "../../assets/images/feather-bar-chart.svg";
import img3 from "../../assets/images/material-timelapse.svg";
import img4 from "../../assets/images/material-view-module.svg";
import "./courseSlider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import jwt_decode from "jwt-decode";
import global from "../../servicesvariable";

export default function CourseSlider(props) {
  const [userprogress, setUserprogress] = useState([]);
  const [tcounter, setTcounter] = useState([]);
  const [selectedcourses, setSelectedcourses] = useState([]);
  const token = localStorage.getItem("token");
  const [allsubcourse, setallsubcourse] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const getAllStudentColleagueProgressedCourses = async () => {
    try {
      setLoading(true);
      const limit = 8;
      const progressedCourses =
        await CourseService.getAllStudentColleagueProgressedCourses(limit);
      let courses = progressedCourses.data.data;
      setUserprogress(courses);
      if (tcounter.length === 0) {
        for (let i = 0; i < courses.length; i++) {
          const tplan = await CourseService.getTrainingPlan(
            courses[i].courseId.name
          );
          if (tplan.data) {
            let length = {
              length: tplan.data.length,
              name: courses[i].courseId.name,
            };
            setTcounter((d) => [...d, length]);
          } else {
            let length = {
              length: 0,
              name: courses[i].courseId.name,
            };
            setTcounter((d) => [...d, length]);
          }
          if (i === courses.length - 1) {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error["response"]);
      console.log(
        "error getting getAllStudentColleagueProgressedCourses",
        error
      );
    }
  };
  useEffect(() => {
    getAllStudentColleagueProgressedCourses();
  }, []);

  useEffect(() => {
    setLoading(true);
    setConfirmBox(false);
    CourseService.getSubCourses()
      .then((res) => {
        if (res.data) {
          setallsubcourse(res.data);
        }
      })
      .catch((ex) => { });
    if (token) {
      const decoded = jwt_decode(token);

      LoginService.getSingleUser(decoded.id, token)
        .then((res) => {
          if (res.data) {
            CourseService.getSubCoursesIn(
              { courses: res.data.courses },
              token
            ).then((result) => {
              if (result.data) {
                setSelectedcourses(result.data);
              } else {
                setSelectedcourses([]);
              }
            });
          } else {
            navigate("/login");
          }
          setLoading(false);
        })
        .catch((ex) => { });
    }
  }, [confirmBox]);
  const goToDescription = (name) => {
    navigate("/description/" + name);
  };
  const handleAutoSubscribe = (sid) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    LoginService.AddSubscribeCourse(decoded.role, decoded.id, sid, token)
      .then((res) => {
        setConfirmBox(true);
      })
      .catch((ex) => { });
  };
  const newlyAddedCourses = (item, index) => {
    return (
      <div class="learncourse-slider w-100">
        <div class="card mb-lg-3">
          <div class="course-item-img">
            {item.courseId.icon === "default.jpg" ? (
              <img
                src={`./../${item.courseId.icon}`}
                class="card-img-top"
                alt={item.courseId.icon}
                height="150"
                width="150"
              ></img>
            ) : item.courseId.icon.match(/http/g) ? (
              <img
                src={item.courseId.icon}
                class="card-img-top"
                alt={item.courseId.icon}
                height="150"
                width="150"
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${item.courseId.icon}`}
                  class="card-img-top"
                  alt={item.courseId.name}
                  height="150"
                  width="150"
                ></img>
              </>
            )}
          </div>

          <div class="card-body d-flex flex-column p-0">
            <div class="course-content p-3 pb-3 pb-lg-0">
              <h4
                class="card-title text-black font-weight-400"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.courseId.name?.replaceAll("_", " ").replaceAll("-", " ")}
              </h4>
              <p
                className="card-text font-14 text-dark "
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.courseId.desc}
              </p>
            </div>
            <div class="course-details mt-auto">
              <div class="px-3 py-2 course-item d-flex align-items-center">
                <img
                  src={img4}
                  alt="Modules"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.courseId.name && (
                              <>{Math.ceil(tcounter[i].length)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Modules
                </span>
              </div>
              <div class="px-3 py-2 course-item d-flex align-items-center">
                <img
                  src={img3}
                  alt="Hours"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.courseId.name && (
                              <>{Math.ceil(tcounter[i].length * 8.5)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Hours
                </span>
              </div>
              <div class="px-3 py-2 course-item d-flex align-items-center">
                {item?.courseId?.level && (
                  <>
                    <img
                      src={img2}
                      alt="level"
                      width="16"
                      height="21"
                      class="me-3"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      For <strong>{item.courseId.level}</strong>
                    </span>
                  </>
                )}
              </div>
              <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2 btn-wrapper">
                {allsubcourse.map((subcourse) => {
                  if (subcourse.name === item.courseId.name) {
                    return (
                      <Link
                        to={`/course/${subcourse.type.name}/courseDetails/${item.courseId.name}`}
                        class="text-primary sm-white-btn"
                        title="Course Details"
                      >
                        Course Details
                      </Link>
                    );
                  }
                })}
                <Link to={`/login`} class="text-primary  sm-btn">
                  Subscribe
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const subscribeCourses = (item, index) => {
    return (
      <div class="learncourse-slider">
        <div class="card mb-lg-3">
          <div class="course-item-img">
            {item.courseId.icon === "default.jpg" ? (
              <img
                src={`./../${item.courseId.icon}`}
                class="card-img-top"
                alt={item.courseId.icon}
                height="150"
                width="150"
              ></img>
            ) : item.courseId.icon.match(/http/g) ? (
              <img
                src={item.courseId.icon}
                class="card-img-top"
                alt={item.courseId.icon}
                height="150"
                width="150"
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${item.courseId.icon}`}
                  class="card-img-top"
                  alt={item.courseId.name}
                  height="150"
                  width="150"
                ></img>
              </>
            )}
          </div>

          <div class="card-body d-flex flex-column p-0">
            <div class="course-content p-3 pb-3 pb-lg-0">
              <h4
                class="card-title text-black font-weight-400"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.courseId.name?.replaceAll("_", " ").replaceAll("-", " ")}
              </h4>
              <p
                className="card-text font-14 text-dark "
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.courseId.desc}
              </p>
            </div>
            <div class="course-details mt-auto">
              <div class="px-3 py-2 course-item d-flex align-items-center">
                <img
                  src={img4}
                  alt="Modules"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.courseId.name && (
                              <>{Math.ceil(tcounter[i].length)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Modules
                </span>
              </div>
              <div class="px-3 py-2 course-item d-flex align-items-center">
                <img
                  src={img3}
                  alt="Hours"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.courseId.name && (
                              <>{Math.ceil(tcounter[i].length * 8.5)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Hours
                </span>
              </div>
              <div class="px-3 py-2 course-item d-flex align-items-center">
                {item?.courseId?.level && (
                  <>
                    <img
                      src={img2}
                      alt="level"
                      width="16"
                      height="21"
                      class="me-3"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      For <strong>{item.courseId.level}</strong>
                    </span>
                  </>
                )}
              </div>
              <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2 btn-wrapper">
                {allsubcourse.map((subcourse) => {
                  if (subcourse.name === item.courseId.name) {
                    return (
                      <Link
                        to={`/course/${subcourse.type.name}/courseDetails/${item.courseId.name}`}
                        class="text-primary sm-white-btn"
                        title="Course Details"
                      >
                        Course Details
                      </Link>
                    );
                  }
                })}
                <Link
                  // to={`/course/HTML/subcourse/HTML`}
                  onClick={() => {
                    handleAutoSubscribe(item.courseId._id);
                  }}
                  class="text-primary sm-btn"
                >
                  Subscribe
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const subscribedCourses = (item, index) => {
    return (
      <div class="learncourse-slider">
        <div class="card mb-lg-3 subscribe-card">
          <div class="course-item-img">
            {item.courseId.icon === "default.jpg" ? (
              <img
                src={`./../${item.courseId.icon}`}
                class="card-img-top"
                alt={item.courseId.name}
                height="150"
                width="150"
              ></img>
            ) : item.courseId.icon.match(/http/g) ? (
              <img
                src={item.courseId.icon}
                class="card-img-top"
                alt={item.courseId.name}
                height="150"
                width="150"
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${item.courseId.icon}`}
                  class="card-img-top"
                  alt={item.courseId.name}
                  height="150"
                  width="150"
                ></img>
              </>
            )}
          </div>
          <div class="card-body d-flex flex-column p-0">
            <div class="course-content p-3 pb-3 pb-lg-0">
              <h4
                class="card-title text-black font-weight-400"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.courseId.name.replaceAll("_", " ").replaceAll("-", " ")}
              </h4>
              <p
                className="card-text font-14 text-dark "
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.courseId.desc}
              </p>
            </div>
            <div class="course-details mt-auto">
              <div class="px-3 py-2 course-item d-flex align-items-center">
                <img
                  src={img4}
                  alt="Modules"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.courseId.name && (
                              <>{Math.ceil(tcounter[i].length)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Modules
                </span>
              </div>
              <div class="px-3 py-2 course-item d-flex align-items-center">
                <img
                  src={img3}
                  alt="Hours"
                  width="16"
                  height="21"
                  class="me-3"
                ></img>{" "}
                <span class="font-14 text-dark-gray">
                  <strong>
                    {(() => {
                      const t = [];
                      for (let i = 0; i < tcounter.length; i++) {
                        t.push(
                          <>
                            {tcounter[i].name == item.courseId.name && (
                              <>{Math.ceil(tcounter[i].length * 8.5)}</>
                            )}
                          </>
                        );
                      }
                      return t;
                    })()}
                  </strong>{" "}
                  Hours
                </span>
              </div>
              <div class="px-3 py-2 course-item d-flex align-items-center">
                {item?.courseId?.level && (
                  <>
                    <img
                      src={img2}
                      alt="level"
                      width="16"
                      height="21"
                      class="me-3"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      For <strong>{item.courseId.level}</strong>
                    </span>
                  </>
                )}
              </div>
              <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2">
                {allsubcourse.map((subcourse) => {
                  if (subcourse.name === item.courseId.name) {
                    return (
                      <>
                        <Link
                          to={`/course/${subcourse.type.name}/courseDetails/${item.courseId.name}`}
                          class="text-primary sm-white-btn"
                          title="Course Details"
                        >
                          Course Details
                        </Link>
                        <Link
                          to={`/course/${subcourse.type.name}/courseOverview/${item.courseId.name}/tplan#1`}
                          class="text-success sm-btn d-flex align-items-center justify-content-center"
                        >
                          <img
                            width="16"
                            height="16"
                            alt="bar btn"
                            src={
                              require("../../assets/images/check-icon.svg")
                                .default
                            }
                          />
                          &nbsp;Continue
                        </Link>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      {userprogress && userprogress.length > 0 && (
        <section className={`container ${token ? "py-0" : ""}`}>
          <div class="row">
            <div class="learning-card-wrapper">
              <div class="learn-course position-relative">
                <h2 class="text-dark-gray font-weight-400 mb-2">
                  What your colleagues are learning
                </h2>
                <div class="px-12px">
                  <Slider {...settings}>
                    {userprogress.map((item, index) => {
                      return localStorage.getItem("token") ? (
                        jwt_decode(localStorage.getItem("token")).role ===
                          "Student" ? (
                          Array.from(selectedcourses, (o) => o.name).indexOf(
                            item.courseId.name
                          ) === -1 ? (
                            <>{subscribeCourses(item, index)}</>
                          ) : (
                            <>{subscribedCourses(item, index)}</>
                          )
                        ) : (
                          <>{subscribedCourses(item, index)}</>
                        )
                      ) : (
                        <>{newlyAddedCourses(item, index)}</>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
